import { api } from "@/services/api";
import useSWR from "swr";
import { useCallback } from "react";
import { UseApiProps } from "./use-api.types";

export const useApi = ({ method, path, body, service = api }: UseApiProps) => {
  const fetcher = useCallback(
    (url: string) =>
      service[method](url, body).then((res) => {
        if (res && res.data) {
          return res.data;
        }
        return [];
      }),
    [],
  );

  const { data, mutate, isValidating } = useSWR(path, fetcher, {
    suspense: true,
    revalidateOnFocus: false,
    dedupingInterval: 60000,
  });

  const isLoading = isValidating;

  return { data, mutate, isLoading };
};
