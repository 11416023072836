/// <reference types="vite-plugin-svgr/client" />

import {
  Children,
  cloneElement,
  createRef,
  FunctionComponent,
  isValidElement,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { DropdownProps } from "./dropdown.types";

import { ReactComponent as ChevronIcon } from "@/assets/icons/chevron.svg";

import * as S from "./dropdown.styles";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export const Dropdown: FunctionComponent<DropdownProps> = ({
  children,
  value,
  icon,
  disabled,
  variant,
  rightIcon,
  onOpenChange,
  isFixed = false,
  ...inputProps
}) => {
  const [width, setWidth] = useState<number>();

  const triggerCallbackRef = useCallback((node: HTMLButtonElement) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const inputRef = createRef<HTMLInputElement>();

  const onSelectOption: MouseEventHandler<HTMLOptionElement> = ({
    currentTarget,
  }) => {
    inputRef.current?.setAttribute("value", currentTarget.value);
  };

  const childrenWithRewritedProps = Children.map(children, (child) => {
    if (!isValidElement(child)) return;
    return cloneElement(child as ReactElement<any>, {
      onSelect: (event: any) => {
        onSelectOption(event);
        child.props.onSelect?.(event);
      },
    });
  });

  const controlledInputProps = value ? { readOnly: true, value } : {};

  return (
    <DropdownMenu onOpenChange={onOpenChange}>
      <DropdownMenuTrigger asChild>
        <S.DropdownTrigger
          variant={variant}
          disabled={disabled}
          ref={triggerCallbackRef}
        >
          {icon}
          <input
            {...inputProps}
            {...controlledInputProps}
            ref={inputRef}
            disabled={disabled}
            tabIndex={-1}
          />
          {rightIcon}
          <ChevronIcon />
        </S.DropdownTrigger>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {childrenWithRewritedProps?.map((child, index) => (
          <DropdownMenuItem
            key={index}
            className="hover:bg-transparent focus:bg-transparent p-0"
          >
            {child}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
