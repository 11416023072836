import { DEFAULT_ROUTES } from "@/constants/routes";
import { useActionsTrack } from "@/features/analytics";
import { useAuth } from "@/features/authentication";
import { wobaBackendApi } from "@/services/woba-api";
import { cookieManager } from "@/utils/cookie-manager";
import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IMPORT_STEPS } from "../../constants/import-steps";
import { useGetTotalSubscribers } from "../../../subscribers/hooks/use-get-total-subscribers";
import { useGetTotalUsers } from "../../hooks/use-get-total-users";
import { ImportContextProps, ImportData } from "./import-context.types";

const MembersContext = createContext<ImportContextProps>(
  {} as ImportContextProps,
);

export const ImportProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const stateStep = cookieManager.get<string>({
    cookieName: "currentStep",
  });
  const [currentStep, setCurrentStep] = useState(stateStep);
  const [succesFile, setSuccessFile] = useState<boolean | null>();
  const [importData, setImportData] = useState<ImportData>();
  const [importInfo, setImportInfo] = useState<{
    total: number;
    afterNew: number;
    afterUpdate: number;
  }>({
    total: 0,
    afterNew: 0,
    afterUpdate: 0,
  });
  const [currentOption, setCurrentOption] = useState<"new" | "update" | "">("");
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [feedbackData, setFeedbackData] = useState<
    | {
        title: string;
        description: string;
        status: "success" | "error";
        actionSuccess?: () => void;
      }
    | null
    | undefined
  >(null);
  const [file, setFile] = useState<File>();

  const [translate] = useTranslation(["members"]);

  const actualDate = new Date();
  const expirationDate = new Date(
    new Date(actualDate).setMonth(actualDate.getMonth() + 1),
  );

  const { onActionClick } = useActionsTrack();

  const { userInfo } = useAuth();

  const changeStatus = (status: boolean | null) => {
    setSuccessFile(status);
  };

  useEffect(() => {
    cookieManager.set({
      cookieName: "currentStep",
      value: currentStep,
      globally: true,
      expirationDate,
    });
  }, [currentStep]);
  useEffect(() => {
    setCurrentStep(stateStep);
  }, [stateStep]);

  useEffect(() => {
    if (!stateStep) {
      cookieManager.set({
        cookieName: "currentStep",
        value: Object.keys(IMPORT_STEPS)[0],
        globally: true,
        expirationDate,
      });
      setCurrentStep(Object.keys(IMPORT_STEPS)[0]);
    }
  }, []);

  const totalMembers = useGetTotalUsers({
    league: Number(userInfo?.league?.id),
  });

  const totalSubscribes = useGetTotalSubscribers({
    league: Number(userInfo?.league?.id),
  });

  useEffect(() => {
    const allMembers = totalMembers + totalSubscribes;

    setImportInfo({
      total: allMembers,
      afterNew: Math.max(
        0,
        allMembers + (importData?.subscribersToInsert?.length || 0),
      ),
      afterUpdate: Math.max(
        0,
        allMembers +
          (importData?.subscribersToInsert?.length || 0) -
          ((importData?.usersToDelete?.length || 0) +
            (importData?.subscribersToDelete?.length || 0)),
      ),
    });
  }, [importData]);

  const findStepName = (id: number) => {
    return Object.entries(IMPORT_STEPS)?.find(
      (item) => item[1]?.id === id,
    )?.[0];
  };

  const stepId = IMPORT_STEPS[currentStep as keyof typeof IMPORT_STEPS]?.id;

  const nextStep = () => {
    const next = findStepName(stepId === 3 ? 3 : stepId + 1);

    setCurrentStep(next as string);
    onActionClick("MEMBERS_IMPORT_ADVANCED", "MEMBERS", {
      url_page: DEFAULT_ROUTES.MEMBERS_IMPORT,
    });
  };
  const previousStep = () => {
    const goBackStep = findStepName(stepId === 1 ? 1 : stepId - 1);

    setCurrentStep(goBackStep as string);
  };

  const navigate = useNavigate();

  const confirmImport = async () => {
    changeStatus(null);
    setConfirmModalOpen(false);
    setFeedbackData(undefined);
    const validation = currentOption === "new";

    try {
      onActionClick("MEMBERS_IMPORT_COMPLETE", "MEMBERS", {});
      const response = await wobaBackendApi.post(
        `company-subscribers/${userInfo?.league?.id}/apply-processed-subscribers?processDelete=${!validation}`,
      );

      if (response) {
        setConfirmModalOpen(false);

        setFeedbackData({
          title: translate("members-import-feedback-title-success"),
          description: translate("members-import-feedback-description-success"),
          status: "success",
          actionSuccess: () => {
            navigate("/members");
            setFeedbackData(null);
          },
        });
      } else {
        setConfirmModalOpen(false);

        setFeedbackData({
          title: translate("members-import-feedback-title-error"),
          description: translate("members-import-feedback-description-error"),
          status: "error",
        });
      }
    } catch (error) {
      setFeedbackData({
        title: translate("members-import-feedback-title-error"),
        description: translate("members-import-feedback-description-error"),
        status: "error",
      });

      return { status: false };
    }
  };
  const closeError = () => setFeedbackData(null);

  const isFirstStep = stepId === 1;

  return (
    <MembersContext.Provider
      value={{
        nextStep,
        currentStep,
        isFirstStep,
        previousStep,
        stepId,
        changeStatus,
        succesFile,
        importData,
        setImportData,
        importInfo,
        setCurrentOption,
        currentOption,
        confirmModalOpen,
        setConfirmModalOpen,
        confirmImport,
        feedbackData,
        closeError,
        setFile,
        file,
      }}
    >
      {children}
    </MembersContext.Provider>
  );
};

export const useImport = () => useContext(MembersContext);
