import { FunctionComponent, useMemo, useRef } from "react";
import { DropdownItemProps } from "./dropdown-item.types";

import * as S from "./dropdown-item.styles";
import { Slot } from "@radix-ui/react-slot";

export const DropdownItem: FunctionComponent<DropdownItemProps> = ({
  label,
  value,
  onSelect,
  icon,
  asChild,
  disabled,
  ...props
}) => {
  const optionRef = useRef<HTMLButtonElement>(null);

  const Component = useMemo(() => (asChild ? Slot : "button"), [asChild]);

  return (
    <S.DropdownItem
      data-disabled={disabled}
      onClick={(event) => {
        if ((event.target as HTMLElement).tagName === "DIV") {
          optionRef.current?.click();
        }
      }}
    >
      {icon}
      <Component
        ref={optionRef}
        value={value}
        onClick={onSelect}
        children={label}
        {...props}
      />
    </S.DropdownItem>
  );
};
