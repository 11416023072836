export type ROUTE =
  | "INDEX"
  | "DASHBOARD"
  | "INSIGHTS"
  | "REPORTS"
  | "MEMBERS"
  | "MEMBERS_IMPORT"
  | "MEMBERS_SUBSCRIBERS"
  | "MEMBERS_GROUPS"
  | "GROUP_DETAILS"
  | "SCHEDULINGS"
  | "CONTRACTS"
  | "CONTRACTS_PAYMENTS"
  | "PAYMENTS"
  | "REVIEWS"
  | "BOOKINGS_REPORT"
  | "PACKAGES"
  | "PACKAGE"
  | "PRIVATE_OFFICES"
  | "PRIVATE_OFFICES_SCHEDULINGS";

export const DEFAULT_ROUTES: { [key in ROUTE]: string } = {
  INDEX: "/",
  DASHBOARD: "/painel",
  INSIGHTS: "/insights",
  REPORTS: "/reports",
  MEMBERS: "/members",
  MEMBERS_IMPORT: "/members/import",
  MEMBERS_SUBSCRIBERS: "/members/subscribers",
  MEMBERS_GROUPS: "/members/groups",
  GROUP_DETAILS: "/members/group-details/:id",
  SCHEDULINGS: "/schedulings",
  CONTRACTS: "/contracts",
  CONTRACTS_PAYMENTS: "/contracts/payments/:id",
  PAYMENTS: "/payments",
  REVIEWS: "/dashboard/reviews",
  BOOKINGS_REPORT: "/dashboard/bookings-report",
  PACKAGES: "/packages",
  PACKAGE: "/packages/:id",
  PRIVATE_OFFICES: "/private-offices",
  PRIVATE_OFFICES_SCHEDULINGS: "/private-offices/schedulings",
};

export const DEMO_ROUTES: { [key in ROUTE]: string } = {
  INDEX: "/demo",
  DASHBOARD: "/demo/dashboard",
  INSIGHTS: "/demo/insights",
  REPORTS: "/demo/reports",
  MEMBERS: "/demo/members",
  MEMBERS_SUBSCRIBERS: "/demo/members/subscribers",
  MEMBERS_GROUPS: "/demo/members/groups",
  GROUP_DETAILS: "/demo/members/group-details/:id",
  MEMBERS_IMPORT: "/demo/members/import",
  SCHEDULINGS: "/demo/schedulings",
  CONTRACTS: "/demo/contracts",
  CONTRACTS_PAYMENTS: "/demo/contracts/payments/:id",
  PAYMENTS: "/demo/payments",
  REVIEWS: "/demo/dashboard/reviews",
  BOOKINGS_REPORT: "/demo/dashboard/bookings-report",
  PACKAGES: "/demo/packages",
  PACKAGE: "/demo/packages/:id",
  PRIVATE_OFFICES: "/demo/private-offices",
  PRIVATE_OFFICES_SCHEDULINGS: "/demo/private-offices/schedulings",
};

export const ROUTES = window.location.pathname.startsWith(DEMO_ROUTES.INDEX)
  ? DEMO_ROUTES
  : DEFAULT_ROUTES;
