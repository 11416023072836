const LoadingIndicator = () => (
  <div className="flex items-center justify-center h-screen flex-col z-[9999] relative w-full bg-white">
    <span className="mb-5 text-lg font-bold">Aguarde</span>
    <div className="flex space-x-2">
      <div className="w-4 h-4 bg-primary-500 rounded-full animate-bounce delay-0"></div>
      <div className="w-4 h-4 bg-complementary2-500 rounded-full animate-bounce delay-200"></div>
      <div className="w-4 h-4 bg-complementary4-500 rounded-full animate-bounce delay-400"></div>
      <div className="w-4 h-4 bg-complementary-500 rounded-full animate-bounce delay-600"></div>
    </div>
  </div>
);

export default LoadingIndicator;
