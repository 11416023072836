import { useApi } from "@/hooks/use-api";
import { GetUsersProps, UsersResponse } from "./get-users.types";

export const getUsersApi = ({
  league,
  page = 1,
  search = "",
  groupId,
  limit,
}: GetUsersProps) => {
  const searchParams = new URLSearchParams();

  const limitValue = isNaN(Number(limit)) || !limit ? 10 : Number(limit);

  searchParams.append("league_id", `${league}`);
  searchParams.append("group_id", `${groupId}`);
  searchParams.append("search", search);
  searchParams.append("limit", `${limitValue}`);
  searchParams.append("page", `${page}`);

  const { data, mutate }: UsersResponse = useApi({
    path: `/v1/users?${searchParams.toString()}`,
    method: "get",
  });

  return {
    data: data._embedded.users,
    total: data.total,
    count: data.count,
    mutate,
  };
};
