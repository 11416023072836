import { styled } from "@woba/ds-web";
import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import { Variants } from "./dropdown.types";

export const DropdownWrapper = styled(DropdownPrimitives.Root, {});

const variants: Variants = {
  variant: {
    primary: {
      backgroundColor: "white",
      borderStyle: "solid",
      borderWidth: "$border-01",
      borderColor: "$neutral-200",
      borderRadius: "$sm",
      padding: "$space-03 $space-04",

      input: {
        color: "$neutral-900",
      },

      "&:hover": {
        backgroundColor: "$neutral-100",

        input: {
          backgroundColor: "$neutral-100",
          color: "$neutral-900",
        },
      },
      position: "relative",
    },
    secondary: {
      border: 0,
      backgroundColor: "transparent",
      padding: "$space-03 0",

      input: {
        backgroundColor: "transparent",
      },
    },
  },
};

export const DropdownTrigger = styled(DropdownPrimitives.Trigger, {
  defaultVariants: {
    variant: "primary",
  },

  variants,

  cursor: "pointer",
  userSelect: "none",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "$space-03",

  svg: {
    fill: "$neutral-700",
    transition: "0.2s",
    width: 16,
  },
  "@media (max-width: 768px)": {
    width: "100%",
  },

  '&[data-state="open"]': {
    "svg:last-child": {
      transform: "rotate(180deg)",
    },
  },
  "&:hover": {
    backgroundColor: "$neutral-100",
  },
  "&:disabled": {
    cursor: "unset",
    borderColor: "$neutral-200",
    // backgroundColor: "$neutral-100",
    svg: {
      fill: "$neutral-400",
    },
    input: {
      color: "$neutral-500",
    },
    "&:hover": {
      backgroundColor: "unset",
      input: {
        backgroundColor: "unset",
        color: "$neutral-500",
      },
    },
  },

  input: {
    border: "none",
    cursor: "inherit",
    width: "100%",
  },

  "input::placeholder": {
    color: "$neutral-700",
  },

  "input:disabled": {
    backgroundColor: "transparent",
    "&::placeholder": {
      color: "$neutral-400",
    },
  },
});

export const DropdownLabel = styled(DropdownPrimitives.Label);
